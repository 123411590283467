import "../../Website/output/assets/css/site.css";
import Alpine from '@alpinejs/csp'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.data('expand', () => ({
    expanded: false,
    dropdownId: "",
    value: '+',

    toggle() {
        this.expanded = !this.expanded;
        if (this.value == '+') {
            this.value = '-'
        }
        else {
            this.value = '+'
        }
    },
}))

Alpine.start()
