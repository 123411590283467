import * as process from "./processSwiper";
import * as navbar from "./navbar";
import * as submitForm from "./submitForm";
import * as testimonials from "./testimonials";
import * as insightsFilter from "./insightsFilter";
import * as careerForm from "./submitCareersForm";
import * as staffTestimonials from "./staffTestimonials";
import * as tracking from "./tracking";
import * as animations from "./animation";
import * as numbers from "./numbers";
import * as dialog from "./dialogBox";

import "../../Website/output/assets/css/site.css";
import marketingEmailsForm from "./marketingEmailsForm";

export default {
  navbar,
  submitForm,
  process,
  testimonials,
  insightsFilter,
  careerForm,
  staffTestimonials,
  tracking,
  marketingEmailsForm,
  animations,
  numbers,
  dialog,
};
