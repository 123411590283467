function toggleHiddenClass(item: string): void {
    document.getElementById(item)?.classList.toggle("hidden");
}

function openNavbarPopup(this: HTMLElement, event: Event): boolean {
    event.preventDefault();
    const target = this.getAttribute("data-navbar");

    if (target === null) {
        return true;
    }

    const targetItem = document.getElementById(target);

    if (targetItem === null) {
        return false;
    }

    const dataId = this.getAttribute("data-id");
    const button = document.getElementById(`${dataId}_Button`);
    if (button === null) {
        return false;
    }

    document.querySelectorAll("[navbar-Item]").forEach((i) => {
        if (i == targetItem) {
            targetItem.classList.toggle("active");
        }
        else {
            if (i.classList.contains("active")) {
                i.classList.remove("active");
            }
        }
    });

    document.querySelectorAll("[navbar-chevrons]").forEach((i) => {
        if (i == button) {
            i.classList.toggle('rotate-180');
        }
        else {
            if (i.classList.contains('rotate-180')) {
                i.classList.remove('rotate-180');
            }
        }
    });

    hideNavigationStage2Elements();

    return false;
}

function toggleHamburger(this: HTMLElement): void {
    const target = this.getAttribute("data-navbar-hamburger");

    if (target === null) {
        return;
    }

    toggleHiddenClass(target);
}

function changePlaceholder(this: HTMLElement): void {
    hideNavigationStage2Elements();
    const target = this.getAttribute("data-navbar-children");

    if (target === null) {
        return;
    }

    const element = document.getElementById(target);
    const placeholderItem = document.querySelectorAll(".newsArticlePreset");

    placeholderItem.forEach((item: Element) => {
        if (!item.classList.contains("hidden")) {
            item.classList.add("hidden");
        }
    });

    document
        .querySelectorAll("[data-navbar-children]:not([data-navbar-children=''])")
        .forEach((i) => {
            const targetedItem = i.getAttribute("data-navbar-children");

            if (targetedItem === null) {
                return;
            }

            const item = document.getElementById(targetedItem);

            if (item === null) {
                return;
            }

            if (!item.classList.contains("hidden") && target !== targetedItem) {
                item.classList.add("hidden");
            }
        });

    this?.classList.toggle("bg-navy");

    if (element?.classList.contains("hidden")) {
        element.classList.remove("hidden");
    }
}

function openNavbarHamburgerItem(this: HTMLElement, event: Event): void {
    event.preventDefault();

    const target = this.getAttribute("data-navbar-hamburger-item");
    console.log(target);
    if (target === null) {
        return;
    }

    const targetItem = document.getElementById(target);

    targetItem?.classList.toggle("hidden");
}

function hideNavigationStage2Elements(): void {
    const elements = document.querySelectorAll(".navigation_stage2");
    const buttons = document.querySelectorAll(".toggle_buttons");
    const placeholderItem = document.querySelectorAll(".newsArticlePreset");

    placeholderItem.forEach((item: Element) => {
        if (item.classList.contains("hidden")) {
            item.classList.remove("hidden");
        }
    });

    document
        .querySelectorAll("[data-navbar-children]:not([data-navbar-children=''])")
        .forEach((i) => {
            const targetedItem = i.getAttribute("data-navbar-children");

            if (targetedItem === null) {
                return;
            }

            const item = document.getElementById(targetedItem);

            if (item === null) {
                return;
            }

            if (!item.classList.contains("hidden")) {
                item.classList.add("hidden");
            }
        });

    elements.forEach((element: Element) => {
        if (!element.classList.contains("hidden")) {
            element.classList.add("hidden");
        }
    });

    buttons.forEach((button: Element) => {
        if (button.classList.contains("bg-navy")) {
            button.classList.remove("bg-navy");
        }
    });
}

function toggleSection(this: HTMLElement): void {
    const target = this.getAttribute("data-toggles");

    if (target !== null) {
        toggleHiddenClass(target);
    }
}

export default {
    toggleHiddenClass,
    openNavbarPopup,
    changePlaceholder,
    hideNavigationStage2Elements,
    openNavbarHamburgerItem,
};

document
    .querySelectorAll("[data-navbar]")
    .forEach((i) => i.addEventListener("click", openNavbarPopup));
document
    .querySelectorAll("[data-navbar-hamburger]")
    .forEach((i) => i.addEventListener("click", toggleHamburger));
document
    .querySelectorAll(
        "[data-navbar-hamburger-item]:not([data-navbar-hamburger-item=''])",
    )
    .forEach((i) => i.addEventListener("click", openNavbarHamburgerItem));
document
    .querySelectorAll("[data-navbar-children]:not([data-navbar-children=''])")
    .forEach((i) => i.addEventListener("click", changePlaceholder));
document.querySelectorAll(".ignore-nav-event").forEach((i) =>
    i.addEventListener("click", (event) => {
        event.stopPropagation();
    }),
);
document
    .querySelectorAll("[data-toggles]")
    .forEach((i) => i.addEventListener("click", toggleSection));
