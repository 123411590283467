function toggleDialog(this: HTMLElement): void {

    const dialogBox = document.getElementById("dialogbox");
    if (dialogBox !== null) {
        if (!dialogBox.classList.contains('hidden'))
            dialogBox.classList.toggle('hidden');
    }
}

export default {
    toggleDialog,
};

document
    .querySelectorAll("[dialogClose]")
    .forEach((i) => i.addEventListener("click", toggleDialog));
