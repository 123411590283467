import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { SwiperOptions } from "swiper/types";
import "swiper/css";
import "swiper/css/navigation";

const swiperParams: SwiperOptions = {
  modules: [Navigation],
  loop: true,
  spaceBetween: 24,
  grabCursor: true,
  slidesPerView: "auto",
  direction: "vertical",

  navigation: {
    enabled: false,
    nextEl: "#Process-next",
    prevEl: "#Process-prev",
  },

  breakpoints: {
    768: {
      slidesPerView: 1,
      direction: "horizontal",
      navigation: {
        enabled: true,
      },
    },
    900: {
      slidesPerView: 1.2,
      direction: "horizontal",
      navigation: {
        enabled: true,
      },
    },
    1250: {
      slidesPerView: 2.2,
      direction: "horizontal",
      navigation: {
        enabled: true,
      },
    },
  },
};

new Swiper(".process_swiper", swiperParams);

export default {};
