import Swiper from "swiper";
import { SwiperOptions } from "swiper/types";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const swiperParams: SwiperOptions = {
  loop: true,
  spaceBetween: 32,
  grabCursor: true,
  modules: [Navigation],
  slidesPerView: 1,

  navigation: {
    nextEl: "#staff-Testimonials-next",
    prevEl: "#staff-Testimonials-prev",
  },
};

function PreviousMethod() {
  swiperCards.slidePrev();
}

function NextMethod() {
  swiperCards.slideNext();
}

const swiperCards = new Swiper(".staff-testimonial_card", swiperParams);

export default { PreviousMethod, NextMethod };
