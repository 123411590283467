import { load } from "recaptcha-v3";

const apiUrl =
  "https://prod-30.uksouth.logic.azure.com:443/workflows/4e7b1bdc4f664071aac43b5d84399bb2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=3zvv9OZFZradFoFv28hov06hTp2ltsNjTXlXe8RwO-U";
const contactForm = document.getElementById(
  "marketingContactForm",
) as HTMLFormElement | null;
const responseMessage: HTMLElement | null = document.getElementById(
  "marketing-response-message",
);

if (contactForm) {
  contactForm.addEventListener(
    "submit",
    function (this: HTMLFormElement, e: Event) {
      (async () => {
        e.preventDefault();
        const submitButton: HTMLButtonElement = contactForm.querySelector("[type='Submit']")!;
        submitButton.disabled = true;
        submitButton.textContent = "Processing...";
        const recaptcha = await load("6Lf14NYpAAAAAN8fc2uY5dFND8xyx1WRboxxhBKA");
        const token = await recaptcha.execute("marketingForm");

        interface formDataType {
          firstname: string;
          surname: string;
          business: string;
          email: string;
          recaptcha: string;
        };
        const name: string = ((contactForm.querySelector<HTMLInputElement>("[name='Name']"))!).value;
        const names = name.split(" ");

        if (names.length !== 2) {
          if (names.length === 1) {
            names.push("");
          } else {
            names.splice(1, names.length - 2);
            names.push(names.slice(1).join(" "));
          }
        }

        const formData: formDataType = {
          firstname: names[0],
          surname: names[1],
          email: (contactForm.querySelector<HTMLInputElement>("[name='Email']")!).value,
          business: (contactForm.querySelector<HTMLInputElement>("[name='Business']")!).value,
          recaptcha: token,
        };

        contactForm.reset();

        const jsonFormData = JSON.stringify(formData);

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonFormData,
        };

        const response = await fetch(apiUrl, requestOptions);

        submitButton.disabled = false;
        submitButton.textContent = "Sign up";

        if (response.status === 403) {
          if (responseMessage) {
            responseMessage.textContent = "Please complete the captcha";
            if (responseMessage.classList.contains("hidden")) {
              responseMessage.classList.toggle("hidden");
            }
            responseMessage.classList.add("text-form-fail");
          }
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (contactForm) {
          contactForm.reset();
        }

        if (responseMessage) {
          responseMessage.classList.add("text-form-ok");

          if (responseMessage.classList.contains("hidden")) {
            responseMessage.classList.toggle("hidden");
          }

          responseMessage.textContent = "Successfully Registered";
        }
      })().catch(error => console.error("Error:", error));
    },
  );
}

export default {};
