import { load } from "recaptcha-v3";

const apiUrl =
  "https://prod-26.uksouth.logic.azure.com:443/workflows/a2fe7603d4c44626b18deb4d298c6c41/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RfhbX3XSZ_ZhFyHxInok3pbkkyLxBBA3siZ6G9N-hzc";

const contactForm = document.getElementById(
  "contactForm",
) as HTMLFormElement | null;
const responseMessage: HTMLElement | null =
    document.getElementById("response-message");
const dialogBox: HTMLElement | null = document.getElementById(
    "dialogbox",
);

if (contactForm) {
  contactForm.addEventListener(
    "submit",
    function (this: HTMLFormElement, e: Event) {
      (async () => {
        e.preventDefault();
        const submitButton: HTMLButtonElement = contactForm.querySelector(
          "[type='Submit']",
        )!;
        submitButton.disabled = true;
        submitButton.textContent = "Processing...";
        const recaptcha = await load("6Lf81ycjAAAAAJvr-t9Fvr9P-20TbG-YvL7bX6Z1");
        const token = await recaptcha.execute("contactForm");

        interface formDataType {
          phoneNumber: string;
          fullName: string;
          email: string;
          message: string;
          recaptcha: string;
        };

        const formData: formDataType = {
          phoneNumber: (contactForm.querySelector<HTMLInputElement>("[name='Telephone']")!).value,
          fullName: (contactForm.querySelector<HTMLInputElement>("[name='Name']")!).value,
          email: (contactForm.querySelector<HTMLInputElement>("[name='Email']")!).value,
          message: (contactForm.querySelector<HTMLInputElement>("[name='Message']")!).value,
          recaptcha: token,
        };

        const jsonFormData = JSON.stringify(formData);

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonFormData,
        };

        const response = await fetch(apiUrl, requestOptions);

        submitButton.disabled = false;
        submitButton.textContent = "Request a call back";

        if (response.status === 403) {
          if (responseMessage) {
            if (responseMessage.classList.contains("hidden")) {
              responseMessage.classList.toggle("hidden");
            }

            responseMessage.textContent = "Please complete the captcha";
            responseMessage.classList.add("text-form-fail");
          }
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (dialogBox) {
          if (contactForm) {
            contactForm.reset();
          }

          if (dialogBox.classList.contains("hidden")) {
            dialogBox.classList.toggle("hidden");
          }
        }
      })().catch(error => console.error("Error:", error));
    },
  );
}

export default {};
