import { load } from "recaptcha-v3";

const apiUrl =
  "https://prod-12.uksouth.logic.azure.com:443/workflows/97552eafd21e434d9a8d421753c4c851/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hUgcwU6uQyAfyI93IgXaISeY0yfD_YqsG62mOlKibQo";
const contactForm = document.getElementById(
  "careerForm",
) as HTMLFormElement | null;
const responseMessage: HTMLElement | null =
    document.getElementById("response-message");
const dialogBox: HTMLElement | null = document.getElementById(
  "dialogbox",
);

if (contactForm) {
  contactForm.addEventListener("submit", function (e: Event) {
    (async () => {
      e.preventDefault();
      const submitButton: HTMLButtonElement = contactForm.querySelector(
        "[type='Submit']",
      )!;
      submitButton.disabled = true;
      submitButton.textContent = "Processing...";
      const recaptcha = await load("6Lf81ycjAAAAAJvr-t9Fvr9P-20TbG-YvL7bX6Z1");
      const token = await recaptcha.execute("contactForm");

      interface formDataType {
        number: string;
        name: string;
        email: string;
        role: string;
        cvName: string;
        coverLetterName: string;
        CV?: string;
        coverLetter?: string;
        recaptcha: string;
      };

      const formData: formDataType = {
        number: (contactForm.querySelector<HTMLInputElement>("[name='Telephone']")!).value,
        name: (contactForm.querySelector<HTMLInputElement>("[name='Name']")!).value,
        email: (contactForm.querySelector<HTMLInputElement>("[name='Email']")!).value,
        role: (contactForm.querySelector<HTMLInputElement>("[name='Role']")!).value,
        cvName: "",
        coverLetterName: "",
        recaptcha: token,
      };

      const cvFile: HTMLInputElement = contactForm.querySelector(
        'input[type="file"][name="CV"]',
      )!;
      if (cvFile.files && cvFile.files.length > 0) {
        formData.cvName = cvFile.files[0].name;
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          formData.CV = btoa(fileReader.result as string);
          await collectFiles();
        };
        fileReader.readAsArrayBuffer(cvFile.files[0]);
      } else {
        await collectFiles();
      }

      async function collectFiles() {
        if (contactForm != null) {
          const coverLetterFile: HTMLInputElement = contactForm.querySelector(
            'input[type="file"][name="coverLetter"]',
          )!;

          if (coverLetterFile.files && coverLetterFile.files.length > 0) {
            formData.coverLetterName = coverLetterFile.files[0].name;
            const fileReader = new FileReader();
            fileReader.onload = async () => {
              formData.coverLetter = btoa(fileReader.result as string);
              await sendFormData();
            };
            fileReader.readAsArrayBuffer(coverLetterFile.files[0]);
          } else {
            await sendFormData();
          }
        }
      }

      async function sendFormData() {
        const jsonData = JSON.stringify(formData);
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonData,
        });

        submitButton.disabled = false;
        submitButton.textContent = "Submit application";

        if (response.status === 403) {
          if (responseMessage) {
            responseMessage.textContent = "Please complete the captcha";
            responseMessage.classList.add("text-form-fail");

            if (responseMessage.classList.contains("hidden")) {
              responseMessage.classList.toggle("hidden");
            }
          }
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (dialogBox) {
          if (dialogBox.classList.contains("hidden")) {
            dialogBox.classList.toggle("hidden");
          }

          if (contactForm) {
            contactForm.reset();
          }
        }
      }
    })().catch(error => console.error("Error:", error));
  });
}

export default {};
